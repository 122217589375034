import React from 'react'
import PageWidth from '../PageWidth'

interface StepsProps {
  Title: string
  Subtitle: string
  steps: {
    title: string
    subtitle: string
    body: string
    id: number
  }[]
  button: {
    link: string
    label: string
    location?: string
  }
}

const Steps = ({ Title, Subtitle, steps, button }: StepsProps) => (
  <PageWidth className="blockSteps">
    <p className="blockSteps__subtitle">{Subtitle}</p>
    <h2 className="blockSteps__title">{Title}</h2>
    <div className="blockSteps__divider"></div>
    <div className="blockSteps__columns">
      {steps?.map((step, index) => (
        <div key={step.id} className="blockSteps__feature">
          <p className="blockSteps__featureTitle">{step.title}</p>
          <p className="blockSteps__featureSubTitle">{step.subtitle}</p>
          <div className="blockSteps__featureNumber">
            <p>0{index + 1}</p>
          </div>
          <p className="blockSteps__featureText">{step.body}</p>
        </div>
      ))}
    </div>
    <a className="blockSteps__link" href={button.link}>
      {button.label}
    </a>
  </PageWidth>
)

export default Steps
